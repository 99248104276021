<template>
  <aside
    class="flex flex-col justify-between py-2 w-14 xl:w-56 rounded bg-slate-800 leading-none sticky top-0 xl:top-4 h-screen"
    :class="{
      '-ml-14 lg:-ml-20 xl:-ml-60 opacity-0': !$store.state.showSidebar,
    }"
  >
    <img alt="Vue logo" src="@/assets/logo.png" class="mb-4" />

    <nav
      class="font-semibold 2xl:p-2 text-slate-500 flex flex-col space-y-4 flex-1"
    >
      <router-link
        @click="toggleDropdown"
        to="/sessions"
        class="flex items-center justify-center p-3 xl:rounded xl:justify-between xl:text-xl hover:bg-slate-900 hover:text-slate-300 transitiontransition"
      >
        <div class="flex items-center space-x-2 transition">
          <CalendarIcon class="w-6 h-6" />

          <span class="hidden xl:flex">Serate</span>
        </div>

        <ChevronDownIcon class="hidden xl:flex transition h-4 w-4" />
      </router-link>

      <transition name="fade" v-if="showDropdown" @click="toggleDropdown">
        <div
          class="hidden xl:block bg-slate-900 rounded overflow-hidden transform -translate-y-3"
        >
          <router-link
            @click="showDropdown = false"
            class="flex items-center justify-center p-3 xl:justify-start hover:bg-slate-700 hover:tex-slate-300 transition"
            to="/sessions"
          >
            Tutte le serate
          </router-link>

          <router-link
            @click="showDropdown = false"
            class="flex items-center justify-center p-3 xl:justify-start hover:bg-slate-700 hover:tex-slate-300 transition"
            to="/session/create"
          >
            Crea serata
          </router-link>
        </div>
      </transition>

      <router-link
        @click="showDropdown = false"
        class="flex items-center justify-center space-x-2 p-3 xl:rounded xl:justify-start xl:text-xl hover:bg-slate-900 hover:text-slate-300 transition"
        to="/playlists"
      >
        <PlayIcon class="w-6 h-6" />

        <span class="hidden xl:flex">Playlist</span>
      </router-link>

      <router-link
        @click="showDropdown = false"
        class="flex items-center justify-center space-x-2 p-3 xl:rounded xl:justify-start xl:text-xl hover:bg-slate-900 hover:text-slate-300 transition"
        to="/privacy-policy"
      >
        <DocumentTextIcon class="w-6 h-6" />

        <span class="hidden xl:flex">Privacy Policy</span>
      </router-link>

      <router-link
        @click="showDropdown = false"
        class="flex items-center justify-center space-x-2 p-3 xl:rounded xl:justify-start xl:text-xl hover:bg-slate-900 hover:text-slate-300 transition"
        to="/song-requests"
      >
        <MailIcon class="w-6 h-6" />

        <span class="hidden xl:flex">Richieste canzoni</span>
      </router-link>

      <button
        v-if="isAppleMusicTokenSet"
        class="btn btn--red text-white cursor-pointer flex items-center space-x-2 rounded-none xl:rounded transition duration-300"
        @click="startSync"
      >
        <RefreshIcon class="w-6 h-6" :class="{ spin: syncingPlaylists }" />
        <span class="hidden xl:flex"> {{ syncPlaylistsLabel }}</span>
      </button>
      <button
        v-else
        class="btn btn--red text-white cursor-pointer flex items-center space-x-2 rounded-none xl:rounded transition duration-300"
        @click="fetchAppleMusicToken"
      >
        <span class="hidden xl:flex">Apple Music Login</span>
      </button>
      <button
        v-if="isAppleMusicTokenSet"
        class="btn btn--red text-white cursor-pointer flex items-center space-x-2 rounded-none xl:rounded transition duration-300"
        @click="fetchAppleMusicToken"
      >
        <span class="hidden xl:flex">Refresh Apple Music Token</span>
      </button>
    </nav>
  </aside>
</template>

<script>
import {
  CalendarIcon,
  PlayIcon,
  ChevronDownIcon,
  DocumentTextIcon,
  RefreshIcon,
  MailIcon,
} from "@heroicons/vue/solid";

import { getClient } from "../includes/api";

export default {
  name: "Sidebar",
  components: {
    CalendarIcon,
    PlayIcon,
    ChevronDownIcon,
    DocumentTextIcon,
    RefreshIcon,
    MailIcon,
  },
  data() {
    return {
      showDropdown: false,
      isAppleMusicTokenSet: false,
      syncingPlaylists: false,
    };
  },
  computed: {
    syncPlaylistsLabel() {
      return this.syncingPlaylists ? "Syncing..." : "Sync Playlists";
    },
  },
  async beforeMount() {
      let apiClient = getClient();
      this.isAppleMusicTokenSet = await apiClient.checkAppleMusicToken();
  },
  methods: {
    async logout() {
      this.$store.dispatch("token", "");
      let apiClient = getClient();
      apiClient.setToken(null);
      this.$router.push({ name: "Home" });
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    async fetchAppleMusicToken() {
      try {
        const client = getClient();
        const musicKitConfig = await client.getMusicKitConfig();

        MusicKit.configure({
          developerToken: musicKitConfig.developerToken,
          app: {
            name: "KEspettacolo",
          },
        });

        const musicKit = MusicKit.getInstance();
        await musicKit.unauthorize();

        const musicUserToken = await musicKit.authorize();
        await client.setAppleMusicUserToken(
          musicUserToken,
          musicKitConfig.state
        );
        this.isAppleMusicTokenSet = true;
      } catch (e) {
        window.alert('Apple Music Login fallito');
      }   
    },
    async startSync() {
      try {
        if (
          window.confirm("Sei sicuro di voler sincronizzare le playlist?") ===
          false
        ) {
          return;
        }

        this.syncingPlaylists = true;
        const client = getClient();
        await client.syncAppleMusicPlaylists();
        window.alert("Sincronizzazione completata");
      } catch (e) {
        window.alert(`Sincronizzazione fallita. Errore: ${e.message}`);
      } finally {
        this.syncingPlaylists = false;
      }
    },
  },
};
</script>

<style>
.sidebar nav > .router-link-exact-active {
  @apply bg-blue-500;
}
.spin {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
