import { createStore } from "vuex";

export default createStore({
  state: {
    baseUrl: process.env.VUE_APP_BASE_URL ?? "http://localhost:8080",
    showSidebar: true,
    sticky: false,
    showMobileNav: false,
  },
  mutations: {
    toggleSidebar(state) {
      state.showSidebar = !state.showSidebar;
    },

    toggleMobileNav(state) {
      state.showMobileNav = !state.showMobileNav;
    },

    isSticky(state) {
      state.sticky = !state.sticky;
    },
  },
  modules: {},
});
