<template>
  <Sidebar v-if="!isHome" />

  <router-view
    v-slot="{ Component }"
    class="flex flex-col min-h-screen flex-1 lg:ml-4"
    :class="{ '': !$store.state.showSidebar && !isHome }"
  >
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";

export default {
  name: "Home",
  components: { Sidebar },
  data() {},

  computed: {
    isHome() {
      return this.$route.name == "Home";
    },
  },
};
</script>

<style>
/* body {
  background: black url(~@/assets/bg.jpg) no-repeat center;
  background-size: cover;
} */
</style>
