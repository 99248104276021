import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import { getClient } from "../includes/api";

const requireAuth = (to, from, next) => {
  if (!getClient().hasToken()) {
    next({ name: "Home" });
  } else {
    next();
  }
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { title: "KeSpettacolo Backoffice" },
  },
  {
    path: "/playlists",
    name: "Playlists",
    component: () => import("../views/Playlists.vue"),
    beforeEnter: requireAuth,
    meta: { auth: false, title: "Playlist - KeSpettacolo Backoffice" },
  },
  {
    path: "/playlists/:id/songs",
    name: "Songs",
    component: () => import("../views/Songs.vue"),
    props: true,
    beforeEnter: requireAuth,
    meta: { auth: false, title: "Playlist - KeSpettacolo Backoffice" },
  },
  {
    path: "/sessions",
    name: "Sessions",
    component: () => import("../views/Sessions.vue"),
    beforeEnter: requireAuth,
    meta: { auth: false, title: "Serate - KeSpettacolo Backoffice" },
  },
  {
    path: "/find-session",
    name: "SessionFind",
    component: () => import("../views/SessionFind.vue"),
    beforeEnter: requireAuth,
    meta: { auth: false, title: "Trova serata" },
  },
  {
    path: "/session/:id",
    name: "Session",
    component: () => import("../views/Session.vue"),
    props: true,
    beforeEnter: requireAuth,
    meta: { auth: false, title: "Serata - KeSpettacolo Backoffice" },
  },
  {
    path: "/session/:id/edit",
    name: "SessionEdit",
    component: () => import("../views/SessionEdit.vue"),
    props: true,
    beforeEnter: requireAuth,
    meta: { auth: false, title: "Modifica serata - KeSpettacolo Backoffice" },
  },
  {
    path: "/session/create",
    name: "SessionCreate",
    component: () => import("../views/SessionCreate.vue"),
    props: true,
    beforeEnter: requireAuth,
    meta: { auth: false, title: "Crea serata - KeSpettacolo Backoffice" },
  },
  {
    path: "/deejay/sessions",
    name: "Deejay",
    component: () => import("../views/Deejay.vue"),
    props: true,
    beforeEnter: requireAuth,
    meta: { auth: false, title: "Deejay - Serate - KeSpettacolo Backoffice" },
  },
  {
    path: "/deejay/session/:id",
    name: "DeejaySessionDetails",
    component: () => import("../views/DeejaySessionDetails.vue"),
    props: true,
    beforeEnter: requireAuth,
    meta: { auth: false, title: "Deejay - Serata - KeSpettacolo Backoffice" },
  },
  {
    path: "/privacy-policy",
    name: "Privacy",
    component: () => import("../views/Privacy.vue"),
    props: true,
    beforeEnter: requireAuth,
    meta: { auth: false, title: "Privacy Policy - KeSpettacolo Backoffice" },
  },
  {
    path: "/labels",
    name: "Labels",
    component: () => import("../views/Labels.vue"),
    props: true,
    beforeEnter: requireAuth,
    meta: { auth: false, title: "Gestione labels - KeSpettacolo Backoffice" },
  },
  {
    path: "/song-requests",
    name: "SongRequests",
    component: () => import("../views/SongRequests.vue"),
    beforeEnter: requireAuth,
    meta: { auth: false, title: "Richieste canzoni - KeSpettacolo Backoffice" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: "active",
  routes,
  scrollBehavior(to, from, savedPosition) {
    window.scrollTo(0, 0);
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  next();
});

export default router;
