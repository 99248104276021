<template>
  <div
    class="flex flex-col flex-1 lg:justify-center items-center text-center text-white text-bold min-h-screen px-8"
  >
    <img
      alt="Vue logo"
      src="../assets/logo.png"
      class="mt-20 lg:mt-0 mb-10 w-40 lg:w-60"
    />

    <form
      @submit.prevent="login"
      class="flex flex-col text-left space-y-8 max-w-sm w-full"
    >
      <div class="flex flex-col space-y-2">
        <label for="email" class="leading-none text-sm font-semibold"
          >Email:</label
        >
        <input
          v-model="email"
          type="email"
          name="email"
          value
          class="border-none bg-slate-800 rounded w-full focus:ring-2 focus:ring-sky-500 outline-none"
        />
      </div>

      <div class="flex flex-col space-y-2">
        <label for="password" class="leading-none text-sm font-semibold"
          >Password:</label
        >
        <input
          v-model="password"
          type="password"
          name="password"
          value
          class="border-none bg-slate-800 rounded w-full focus:ring-2 focus:ring-sky-500 outline-none"
        />
      </div>

      <button type="submit" name="button" class="btn btn--red self-center">
        Login
      </button>
    </form>

    <div
      v-if="error"
      class="mt-2 p-1 border rounded-sm text-xs bg-red-100 border-red-200 text-red-500 mb-4"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
import { getClient } from "../includes/api";
export default {
  name: "Home",
  data() {
    return {
      email: null,
      password: null,
      error: null,
    };
  },
  beforeMount() {
    const apiClient = getClient();
    if (apiClient.hasToken()) {
      this.$router.push({ name: "Sessions" });
    }
  },
  methods: {
    async login() {
      try {
        const apiClient = getClient();
        const token = await apiClient.fetchToken(this.email, this.password);
        apiClient.setToken(token);
        this.$router.push({ name: "Sessions" });
      } catch {
        this.error =
          "Attenzione! Non hai inserito alcuna credenziale o i quelle che hai inserito risultano errate";
      }
    },
  },
};
</script>
