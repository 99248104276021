import Axios, { AxiosResponse, AxiosInstance } from "axios";
import jwtDecode from "jwt-decode";
import cookie from "js-cookie";

export default class ApiClient {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.token = null;
    this.axios = Axios.create({
      baseURL: baseUrl,
    });
  }

  handleAxiosError(error) {
    //@see: https://axios-http.com/docs/handling_errors
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return {
        name: error.response.data.error.name,
        message: error.response.data.error.message,
        code: error.response.status,
      };
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      return {
        name: "requestError",
        message: "General error",
        code: 0,
      };
    } else {
      // Something happened in setting up the request that triggered an Error
      return {
        name: "generalError",
        message:
          "Something happened in setting up the request that triggered an Error",
        code: 1,
      };
    }
  }

  handleApiError(errorMessage) {
    return {
      name: "apiError",
      message: errorMessage,
      code: 2,
    };
  }

  /**
   * @return {string}
   */
  getToken() {
    return this.token;
  }

  hasToken() {
    const token = this.getToken();
    if (token === undefined || token === null) {
      return false;
    }
    return true;
  }

  /**
   *
   * @param {string} token
   */
  setToken(token) {
    this.token = token;
    if (token === null) {
      cookie.remove("token");
      delete this.axios.defaults.headers.common["Authorization"];
      return;
    }

    this.axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    cookie.set("token", token, { expires: 365, secure: false });
  }

  /**
   *
   * @param {string} email
   * @param {string} password
   * @return {Promise<string>}
   */
  async fetchToken(email, password) {
    try {
      const result = await this.axios.post("users/login", {
        email: email,
        password: password,
      });
      if (result.data === undefined || result.data.token === undefined) {
        throw this.handleApiError("Token not obtained");
      }
      return result.data.token;
    } catch (error) {
      throw this.handleAxiosError(error);
    }
  }

  /**
   * @returns {Promise<boolean>}
   */
  async checkAppleMusicToken() {
    try {
      const result = await this.axios.get("appleMusic/token/check");
      return result.data.check;
    } catch (error) {
      throw this.handleAxiosError(error);
    }
  }

  /**
   * @return {Promise<Array>}
   */
  async getPlaylists() {
    try {
      const result = await this.axios.get("playlists");
      if (result.data === undefined) {
        throw this.handleApiError("Playlists not obtained");
      }
      return result.data;
    } catch (e) {
      throw this.handleAxiosError(e);
    }
  }

  async getPlaylist(id) {
    try {
      const result = await this.axios.get(`playlists/${id}`);
      if (result.data === undefined) {
        throw this.handleApiError("Playlist not found");
      }

      return result.data;
    } catch (e) {
      throw this.handleAxiosError(e);
    }
  }

  async updatePlaylist(id, data) {
    try {
      const result = await this.axios.patch("playlists/" + id, data);
      if (result.status !== 200) {
        throw this.handleApiError(`Failed to update playlist ${id}`);
      }

      return result.data;
    } catch (error) {
      throw this.handleAxiosError(error);
    }
  }

  /**
   * @param {string} id
   * @return {Promise<Array>}
   */
  async getSongs(id) {
    try {
      const result = await this.axios.get("playlists/" + id + "/songs");
      if (result.data === undefined) {
        throw this.handleApiError("Playlists not obtained");
      }
      return result.data;
    } catch (e) {
      throw this.handleAxiosError(e);
    }
  }

  /**
   * @return {Promise<string>}
   */
  async getAllSessions({ limit, offset, from, to }) {
    try {
      const query = {
        limit: limit ?? 20,
        offset: offset ?? 0,
      };
      if (from) {
        query.from = from.toISOString().split(".")[0] + "Z";
      }
      if (to) {
        query.to = to.toISOString().split(".")[0] + "Z";
      }
      const result = await this.axios.post("sessions/find", query);
      return result.data;
    } catch (e) {
      throw this.handleAxiosError(e);
    }
  }

  async getSession(id) {
    try {
      const result = await this.axios.get("/sessions/" + id);
      if (result.data !== undefined) {
        return result.data;
      }
      return null;
    } catch (e) {
      throw this.handleAxiosError(e);
    }
  }

  /**
   * @param {string} code
   * @return {Promise<Object>}
   */
  async SessionFindByCode(code) {
    try {
      const result = await this.axios.post("/sessions/findByCode", {
        code: code,
      });
      if (result.data !== undefined) {
        return result.data;
      }
      return null;
    } catch (e) {
      throw this.handleAxiosError(e);
    }
  }

  /**
   * @param {string} id
   * @return {Promise<string>}
   */
  async getSessionPlaylists(id) {
    try {
      const result = await this.axios.get("sessions/" + id + "/playlists");
      if (result.data === undefined) {
        throw this.handleApiError("Playlists not obtained");
      }
      return result.data;
    } catch (e) {
      throw this.handleAxiosError(e);
    }
  }

  /**
   * @param {string} id
   * @return {Promise<string>}
   */
  async getSessionPlaylistsId(id) {
    try {
      const result = await this.axios.get("sessions/" + id + "/playlists");
      if (result.data === undefined) {
        throw this.handleApiError("Playlists not obtained");
      }
      return result.data.id;
    } catch (e) {
      throw this.handleAxiosError(e);
    }
  }

  /**
   * @param {Object} sessionData
   * @return {Promise<Object>}
   */
  async SessionCreate(sessionData) {
    try {
      const result = await this.axios.post("sessions", sessionData);
      if (result.data === undefined) {
        throw this.handleApiError("Section not obtained");
      }
      return result.data;
    } catch (error) {
      throw this.handleAxiosError(error);
    }
  }

  /**
   *
   * @param {string} sessionId
   * @param {Array} playlists an array of plyalists id
   * @return {Promise<boolean>}
   */
  async addPlaylistsToSession(sessionId, playlists) {
    try {
      const result = await this.axios.post(
        "sessions/" + sessionId + "/playlists",
        {
          playlists: playlists,
        }
      );
      if (result.data === undefined) {
        throw this.handleApiError("Playlists not added");
      }
      if (result.status !== 204) {
        throw this.handleApiError(
          "Error occurred during addPlaylistsToSession()"
        );
      }
      return true;
    } catch (error) {
      throw this.handleAxiosError(error);
    }
  }

  async updateSession(id, data) {
    try {
      const result = await this.axios.patch("sessions/" + id, data);
      if (result.status !== 200) {
        throw this.handleApiError("Error occurred during closeSession()");
      }
      return result.data;
    } catch (error) {
      throw this.handleAxiosError(error);
    }
  }

  /**
   *
   * @param {string} sessionId
   * @param {Array} playlists an array of plyalists id
   * @return {Promise<boolean>}
   */
  async closeSession(sessionId) {
    try {
      const result = await this.axios.patch("sessions/" + sessionId, {
        status: "closed",
      });
      if (result.status !== 204) {
        throw this.handleApiError("Error occurred during closeSession()");
      }
      return true;
    } catch (error) {
      throw this.handleAxiosError(error);
    }
  }

  async deleteSession(id) {
    try {
      const result = await this.axios.delete("sessions/" + id);
      if (result.status !== 204) {
        throw this.handleApiError("Error occurred during closeSession()");
      }
    } catch (error) {
      throw this.handleAxiosError(error);
    }
  }

  async resyncSession(id) {
    try {
      await this.axios.post("sessions/" + id + "/resync");
    } catch (error) {
      throw this.handleAxiosError(error);
    }
  }

  /**
   * @param {string} id
   * @return {Promise<Array>}
   */
  async findSongPicks(id, query) {
    try {
      const result = await this.axios.post(
        "sessions/" + id + "/songPicks/find",
        query
      );
      if (result.data === undefined) {
        throw this.handleApiError("Songs not obtained");
      }
      return result.data;
    } catch (e) {
      throw this.handleAxiosError(e);
    }
  }

  /**
   *
   * @param {string} id
   * @return {Promise<Object>}
   */
  async playSong(id) {
    try {
      const result = await this.axios.post("/songPick/" + id + "/status", {
        status: "in_queue",
      });
      if (result.data === undefined) {
        throw this.handleApiError("Song not played!");
      }
      return result.data;
    } catch (error) {
      throw this.handleAxiosError(error);
    }
  }

  /**
   *
   * @param {string} id
   * @return {Promise<Object>}
   */
  async unplaySong(id) {
    try {
      const result = await this.axios.post("/songPick/" + id + "/status", {
        status: "picked",
      });
      console.log(result);
      if (result.data === undefined) {
        throw this.handleApiError("Song played!");
      }
      return result.data;
    } catch (error) {
      throw this.handleAxiosError(error);
    }
  }

  async deleteSongPick(songPickId) {
    try {
      const result = await this.axios.delete("songPicks/" + songPickId);
      if (result.status !== 204) {
        throw this.handleApiError("Failed to delete song pick");
      }
    } catch (error) {
      throw this.handleAxiosError(error);
    }
  }

  /**
   * @return {Promise<Object>}
   */
  async getLabels() {
    try {
      const result = await this.axios.get("labels");
      if (result.data === undefined) {
        throw this.handleApiError("Label not obtained!");
      }
      return result.data;
    } catch (e) {
      throw this.handleAxiosError(e);
    }
  }

  /**
   * @return {Promise<Object>}
   */
  async setLabel(key, value) {
    try {
      const result = await this.axios.post("labels", {
        key: key,
        value: value,
      });
      console.log(result);
      if (result.data === undefined) {
        throw this.handleApiError("Label not set!");
      }
      if (result.status !== 200) {
        throw this.handleApiError("Error occurred during setLabel()");
      }
      return true;
    } catch (error) {
      throw this.handleAxiosError(error);
    }
  }

  async editLabel(key, value) {
    try {
      const result = await this.axios.patch("labels/" + key, {
        value: value,
      });
      console.log(result);
      if (result.data === undefined) {
        throw this.handleApiError("Label not edited!");
      }
      if (result.status !== 200) {
        throw this.handleApiError("Error occurred during setLabel()");
      }
      return true;
    } catch (error) {
      throw this.handleAxiosError(error);
    }
  }

  /**
   * @return {Promise<Object>}
   */
  async deleteLabel(key) {
    try {
      const result = await this.axios.delete("labels/" + key);
      console.log(result);
      if (result.data === undefined) {
        throw this.handleApiError("Label not deleted!");
      }
      if (result.status !== 204) {
        throw this.handleApiError("Error occurred during deleteLabel()");
      }
      return true;
    } catch (error) {
      throw this.handleAxiosError(error);
    }
  }

  /**
   * @param {number} limit
   * @param {number} offset
   * @returns {Promise<{id: string, userId: string, date: string, request: string}[]>}
   */
  async getSongRequests(limit = 10, offset = 0) {
    try {
      const result = await this.axios.get(
        `songRequests?limit=${limit}&offset=${offset}`
      );
      if (result.data === undefined) {
        throw this.handleApiError("Failed to retrieve song requests");
      }
      return result.data;
    } catch (e) {
      throw this.handleAxiosError(e);
    }
  }

  /**
   * @param {string} id
   */
  async deleteSongRequest(id) {
    try {
      const result = await this.axios.delete("songRequests/" + id);
      if (result.status !== 204) {
        throw this.handleApiError("Failed to delete song request");
      }
    } catch (error) {
      throw this.handleAxiosError(error);
    }
  }

  // redirectToSync(redirectUri) {
  //   let baseUrl = `${this.baseUrl}/syncPlaylists`;
  //   if (redirectUri !== undefined) {
  //     baseUrl += `?redirect_uri=${redirectUri}`;
  //   }

  //   window.location.replace(baseUrl);
  // }

  /**
   * @return {Promise<{developerToken: string, state: string}>}
   */
  async getMusicKitConfig() {
    try {
      const result = await this.axios.get("appleMusic/config");
      if (result.data === undefined) {
        throw this.handleApiError("Playlists not obtained");
      }
      return result.data;
    } catch (e) {
      throw this.handleAxiosError(e);
    }
  }

  async setAppleMusicUserToken(musicUserToken, state) {
    try {
      await this.axios.post("appleMusic/token", {
        musicUserToken,
        state,
      });
    } catch (e) {
      throw this.handleAxiosError(e);
    }
  }

  async syncAppleMusicPlaylists() {
    try {
      await this.axios.get("appleMusic/syncPlaylists");
    } catch (e) {
      throw this.handleAxiosError(e);
    }
  }
}

/**
 * {ApiClient}
 */
const currentClient = new ApiClient(
  process.env.VUE_APP_API_BASE_URL ?? "https://api.kespettacolo.com"
);

/**
 * @return {ApiClient}
 */
export function getClient() {
  if (!currentClient.hasToken()) {
    const token = cookie.get("token");
    if (token !== undefined) {
      const claims = jwtDecode(token);
      const exp = new Date(claims.exp * 1000);
      if (Date.now() < exp.getTime()) {
        currentClient.setToken(token);
      }
    }
  }

  return currentClient;
}
